import { useApp } from "context/AppContext";

const Header: React.FC = () => {
	const { companyDetails } = useApp();
	return (
		<header className="bg-white row-span-1 flex justify-center items-center gap-3 z-10 border-b border-solid border-gray-200 py-3">
			{companyDetails?.organisation.agency_logo ? (
				<picture>
					{/* <source media="(max-width: 767px)" srcSet={logo} /> */}
					<source media="(min-width: 768px)" srcSet={companyDetails.organisation.agency_logo} />
					<img
						className="flex justify-center mix-blend-multiply max-h-6"
						src={companyDetails.organisation.agency_logo}
						height={28}
						alt={companyDetails?.organisation.name}
					/>
				</picture>
			) : (
				companyDetails?.organisation.name && (
					<span className="text-primary-600 font-semibold text-base">{companyDetails.organisation.name}</span>
				)
			)}
		</header>
	);
};

export default Header;

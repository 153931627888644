import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { clsx, useDelayedLoading } from "x-wings";
import { getCoTravelersByOrderId, getCompanyDetailsByOrderId, getOrderByOrderId, validateGuestUser } from "./Api";

const AppContext = createContext<AppContextValues | undefined>(undefined);

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
	const orderId = window.location.pathname.split("/").at(2);
	const [order, setOrder] = useState<Order>();
	const [companyDetails, setCompanyDetails] = useState<CompanyDetails>();
	const [isOrderLoading, setIsOrderLoading] = useState(false);
	const [isCompanyDetailsLoading, setIsCompanyDetailsLoading] = useDelayedLoading(false, 600);
	const [isCoTravelersLoading, setIsCoTravelersLoading] = useState(false);
	const [coTravelers, setCoTravelers] = useState<Record<string, CoTraveler>>({});
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	const applicationId = searchParams.get("application_id") ?? "";

	const getOrder = useCallback(async (orderId: string) => {
		try {
			setIsOrderLoading(true);

			const { data } = await getOrderByOrderId(orderId);
			setOrder(data.data);
		} catch (error) {
			console.error(error);
		} finally {
			setIsOrderLoading(false);
		}
	}, []);

	const getCoTravelers = async (orderId: string) => {
		let coTraveler: CoTraveler[] | undefined;
		try {
			setIsCoTravelersLoading(true);
			const {
				data: { data: coTravelerData }
			} = await getCoTravelersByOrderId(orderId);
			coTraveler = coTravelerData;
			setCoTravelers(
				coTravelerData.reduce((prev, current) => {
					return { ...prev, [current._id]: current };
				}, {} as Record<string, CoTraveler>)
			);

			if (!applicationId) {
				// setApplicationId(coTravelerData[0].application_id);
				navigate(`/questionnaire/${orderId}?application_id=${coTravelerData[0].application_id}`);
			}
		} catch (error) {
		} finally {
			setIsCoTravelersLoading(false);
		}

		return coTraveler;
	};

	useEffect(() => {
		const onStorageChange = () => {
			const isSessionExpired = window.localStorage.getItem("session-expired") === "1";
			//'/questionnaire/:orderId'
			//[("", "questionnaire", ":orderId")];
			const isSignUp = window.location.pathname.split("/").at(3) === "signup";

			if (!isSignUp && isSessionExpired) {
				window.location.replace(window.location.pathname + "/signup");
				console.log("Session expired");
			}
		};

		window.addEventListener("storage", onStorageChange);

		return () => window.removeEventListener("storage", onStorageChange);
	}, []);

	useEffect(() => {
		if (!orderId) {
			navigate("/notfound");
			return;
		}
		validateGuestUser(orderId).catch(() => {
			navigate(`/questionnaire/${orderId}/signup`);
		});
	}, [navigate, orderId]);

	useEffect(() => {
		if (!orderId) return;
		setIsCompanyDetailsLoading(true);

		getCompanyDetailsByOrderId(orderId)
			.then(({ data }) => {
				if (data.data) setCompanyDetails(data.data);
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				setIsCompanyDetailsLoading(false);
			});
	}, [orderId, setIsCompanyDetailsLoading]);

	return (
		<AppContext.Provider
			value={{
				order,
				coTravelers,
				applicationId,
				companyDetails,
				isCompanyDetailsLoading,
				getOrder,
				isOrderLoading,
				getCoTravelers,
				setCoTravelers
			}}>
			<div
				className={clsx(
					"fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center gap-2 bg-slate-100 transition-opacity duration-300",
					isCompanyDetailsLoading ? "visible opacity-100" : "pointer-events-none invisible opacity-0"
				)}>
				<span
					className="relative py-2 after:absolute after:-bottom-0.5 after:block after:h-0.5 after:w-full after:origin-center after:animate-[logo-loading_1s_ease-in-out_infinite] after:rounded-full 
				 after:bg-gray-300/60 after:transition-transform">
					Loading...
				</span>
			</div>

			<div
				className={clsx(
					"transition-opacity duration-700 ease-5",
					isCompanyDetailsLoading ? "pointer-events-none invisible opacity-0" : "visible opacity-100"
				)}>
				{children}
			</div>
		</AppContext.Provider>
	);
};

export default AppProvider;

export const useApp = () => {
	const values = useContext(AppContext);

	if (values === undefined) {
		throw new Error("Cannot use useApp hook outside the AppProvider");
	}

	return values;
};

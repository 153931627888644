import { useApp } from "context/AppContext";
import { Flags } from "flagpack-core";
import { useQuestionnaire } from "pages/ShareQuestionnaire";
import React from "react";
import { Flag } from "x-wings";

const Header: React.FC = () => {
	const { order, coTravelers, applicationId, companyDetails } = useApp();
	const { setIsSidebarOpen, setIsCoTravelerModalOpen } = useQuestionnaire();
	const traveler = Object.values(coTravelers).find((t) => t.application_id === applicationId);

	const name = [traveler?.first_name, traveler?.last_name].filter(Boolean).join(" ");

	return (
		<div className="[grid-area:header]">
			{order && (
				<div className="hidden md:flex items-center gap-3 px-6 py-3 border-b border-gray-200 ">
					<Flag code={order.country.symbol as Flags} size="m" hasBorder hasBorderRadius hasDropShadow />
					<span className="text-base text-gray-800 font-semibold tracking-[-0.12px]">
						{order.country.name}
					</span>
					<span className="text-3xl text-gray-300 font-medium">·</span>
					<span className="text-base text-[#404040] font-medium tracking-[-0.12px]">{name}</span>
					<span className="text-3xl text-gray-300 font-medium">·</span>
					<span className="text-base text-[#525252] font-medium tracking-[-0.12px]">Visa Form</span>
				</div>
			)}
			<div className=" relative flex md:!hidden items-center justify-center py-5 border-b border-gray-200">
				{/* <Button type="text" onClick={() => setIsSidebarOpen(true)} className="!p-0 absolute left-[22px]">
					<List className="mx-1" size={24} color="#4B5563" />
				</Button> */}
				{companyDetails?.organisation.agency_logo ? (
					<img
						height={48}
						width={48}
						src={companyDetails?.organisation.agency_logo}
						alt="logo"
						className="h-[22px] w-auto"
					/>
				) : (
					companyDetails?.organisation.name && (
						<span className="text-primary-600 font-semibold text-base">
							{companyDetails.organisation.name}
						</span>
					)
				)}
				{/* <Button
					type="text"
					className="!p-0 absolute right-[22px]"
					onClick={() => setIsCoTravelerModalOpen(true)}>
					<UserPlus className="mx-1" size={24} color="#4B5563" />
				</Button> */}
			</div>
		</div>
	);
};

export default Header;
